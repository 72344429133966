/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "GTM-PVD99RK",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Sai Rakesh Nakirikanti",
  logo_name: "Sai_Rakesh.N()",
  nickname: "Sai / Rakesh",
  full_name: "Sai Rakesh Nakirikanti",
  subTitle: "Oracle HCM Consultant at Shahgaron",
  resumeLink:
    "https://www.linkedin.com/in/sairakesh-nakirikanti-89540a16a/",
  mail: "mailto:sairakesh.n@shahgaron.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "#",
  linkedin:
    "https://www.linkedin.com/in/sairakesh-nakirikanti-89540a16a/",
  gmail: "sairakesh.n@shahgaron.com",
  facebook: "https://www.facebook.com/profile.php?id=100007568057775",
  twitter: "#",
  instagram: "https://www.instagram.com/rakesh13_/",
};

const skills = {
  data: [
    {
      title: "Oracle HCM Consultant",
      fileName: "",
      skills: [
        "⚡ Implement and configure Oracle HCM modules to align with client business processes and requirements.",
        "⚡ Provide expertise in system functionality, troubleshooting issues, and optimizing performance.",
        "⚡ Conduct user training, create documentation, and support go-live activities to ensure successful system adoption.",
        
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Oracle",
          fontAwesomeClassname: "simple-icons:oracle",
          style: {
            color: "#F80000",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "K L University",
      subtitle: "Bachelor of Technology in Electronics and Communication Engineering",
      logo_path: "KLU.png",
      alt_name: "KLU",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, Signal Processing, Networking, Security, etc.",
        "⚡ I have also completed various online courses for AI , React JS , Web App Development, etc.",
        "⚡ I have implemented several projects based on what I've leart from AI related course, you can check my resume for more details ",
      ],
      website_link: "https://kluniversity.in",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AI & Machine Learning",
      subtitle: "Issued by Coursera. Authorized by DeepLearning AI",
      logo_path: "deepLearn.png",
      certificate_link: "https://coursera.org/share/0abcbc0a29a8f00b625fec8a7e03b239",
      alt_name: "AI & Machine Learning",
      color_code: "#d5c591",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "",
  description:
    "With over two years of experience in both technical and functional domains of Oracle HCM Systems, I have developed a comprehensive skill set that allows me to effectively bridge the gap between technology and business needs. My technical expertise includes implementing and configuring various Oracle HCM modules, customizing solutions to meet specific client requirements, and integrating the system with other enterprise applications. On the functional side, I have a deep understanding of HR processes and best practices, enabling me to analyze business requirements, design efficient workflows, and provide valuable insights for process improvement. Additionally, I have a proven track record in troubleshooting and resolving system issues, conducting user training sessions, and ensuring compliance with organizational policies and industry standards. My hands-on experience has equipped me with the ability to deliver robust and scalable Oracle HCM solutions that drive operational efficiency and enhance overall business performance.",
  header_image_path: "bookmark.png",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Oracle HCM Consultant",
          company: "Shahgaron",
          company_url: "https://www.shahgaron.com/",
          logo_path: "logo.jpeg",
          duration: "Aug 2023 - Present",
          location: "HYD/UAE",
          description:
            `System Implementation and Configuration: Implement and configure Oracle HCM modules to meet client-specific business processes and requirements.
● Business Process Analysis: Analyze and document current HR processes, identify gaps, and recommend Oracle HCM solutions.
● Project Management: Manage project timelines, deliverables, and stakeholder communication to ensure timely and successful project completion.
● Customization and Integration: Customize Oracle HCM solutions and integrate them with other enterprise systems as needed.
● Technical Support and Troubleshooting: Provide ongoing technical support, troubleshoot issues, and optimize system performance.
● User Training and Documentation: Develop and deliver user training sessions, create user manuals, and provide post-implementation support.
● Data Migration: Plan and execute data migration from legacy systems to Oracle HCM, ensuring data integrity and accuracy.
● Testing and Quality Assurance: Conduct system testing, including unit, integration, and user acceptance testing, to ensure the solution meets business requirements.
● Compliance and Security: Ensure the Oracle HCM system complies with organizational policies, industry standards, and security regulations.
● Continuous Improvement: Stay updated with Oracle HCM updates and enhancements, and proactively recommend improvements to existing configurations and processes.
`,
          color: "#ff471a",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Cross Winter of Code Mentor",
    //       company: "CWOC",
    //       company_url: "https://crosswoc.ieeedtu.in/",
    //       logo_path: "cwoc.png",
    //       duration: "Feb 2021 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Campus Hustler",
    //       company: "Skillenza",
    //       company_url: "https://skillenza.com/",
    //       logo_path: "skillenza.png",
    //       duration: "Feb 2021 - Present",
    //       location: "Work from Home",
    //       description:
    //         "Spread Awareness of new Technologies and new Opportunities to Students and Grow Skillenza Community.",
    //       color: "#196acf",
    //     },
    //     {
    //       title: "GitHub Student Developer",
    //       company: "GitHub",
    //       company_url: "https://github.com/",
    //       logo_path: "github.png",
    //       duration: "Nov 2019 - Present",
    //       location: "Work from Home",
    //       description:
    //         "Contribute to Open Source Community and Open Source Project.",
    //       color: "#040f26",
    //     },
    //     {
    //       title: "Google Local Guide",
    //       company: "Google Map",
    //       company_url: "https://maps.google.com/localguides/",
    //       logo_path: "localguide.png",
    //       duration: "Sep 2018 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "GDG Student Volunteer",
    //       company: "Google Developer Groups",
    //       company_url: "https://gdg.community.dev/",
    //       logo_path: "gdg.png",
    //       duration: "Feb 2021 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Google Developer Group Surat Student Volunteer and Member.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "E. F. I. Student Volunteer",
    //       company: "ENVIRONMENTALIST FOUNDATION OF INDIA",
    //       company_url: "https://indiaenvironment.org/",
    //       logo_path: "efi.png",
    //       duration: "Apr 2017 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Take time out over weekends to join a gang of nation builders, nature enthusiasts to conserve what is left.",
    //       color: "#5a900f",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Python Scripts, and React Project. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "sai_rakesh.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Vlogs",
    subtitle:
      "I don't vlog frequently but when I do something awesome.",
    link: "https://www.instagram.com/rakesh13_/",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Face-Mask-Detector",
      url: "https://youtu.be/bTxQP6PysRU",
      description:
        "This is an AI based application that detects wether a person has put on a face mask or not. This takes both Live and pre-recorded videos as input.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "1",
      name: "React-Facebook-Clone",
      url: "https://gowrav-facebook.web.app/",
      description:
        "A React Facebook Clone UI with basic functionality such as make a Post.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "2",
      name: "React-WhatsApp-Clone",
      url: "https://gowrav-whatsapp.web.app/",
      description:
        "A React WhatsApp Clone UI with basic functionality such as creating personal chat rooms/groups that every user can access and drop messages into it.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "3",
      name: "React-Hotstar-Clone",
      url: "https://gowrav-hotstar.web.app/",
      description:
        "A React Hotstar Clone UI with basic functionality such as displaying movie cards and giving the user a simillar experience of original build",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Redux",
          iconifyClass: "logos-redux",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "4",
      name: "personal-portfolio",
      url: "https://gowrav143.xyz",
      description:
        "A Personal Portfolio Website that showcases my work and experience.",
      languages: [
        {
          name: "Python",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "4",
      name: "Social-Distance-Detector",
      url: "https://youtu.be/Q6RZ9lvualA",
      description:
        "An AI based application that is used to detect wether the people in the video are maintaing social distance in the given input(either recorded video or live video from a camera)",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "6",
      name: "Human-Activity-Recognition",
      url: "https://youtu.be/UkLSXp4F58g",
      description:
        "Can detect about 300+ human actions taking input from camera or a recorded video",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "10",
      name: "COVID-19 Tracker",
      url: "https://gowrav-covid19-tracker.web.app/",
      description:
        "Simple Covid-19 Tracker made using React and deployed to Firebase.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
